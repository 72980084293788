import React from "react";
import styled from "styled-components";

import { calcColumnWidth } from "../utils/layout";

const BaseButton = styled.button`
  box-sizing: border-box;
  height: 42px;
  padding: 0 14px;
  text-align: center;
  border-radius: 2px;
  background: #145cc6;
  line-height: 40px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  border: none;
  cursor: pointer;
  font-family: Open Sans;
  transition: 0.2s background-color;
  margin-right: ${props => props.marginRight};

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    background-color: #4786ff;
  }

  @media only screen and (max-width: 940px) {
    font-size: 14px;
  }

  &:after {
    display: none;
  }
`;

const DefaultButton = styled(BaseButton)`
  width: ${props =>
    props.width || `calc(${calcColumnWidth(3, 6, "end", true)} - 16px)`};
  max-width: 318px;
  padding: 0;

  @media only screen and (max-width: 940px) {
    max-width: 500px;
    width: ${props => props.width || calcColumnWidth(3, 6, "end", true)}};
  }
`;

const ButtonComponent = props => {
  let Button = DefaultButton;
  if (props.variant === "vendorized") {
    Button = BaseButton;
  }
  return (
    <Button disabled={props.isLoading} {...props}>
      {props.text || props.children}
    </Button>
  );
};

export default ButtonComponent;
