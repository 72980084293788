import React from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import Button from "../../components/Button";

import { calcColumnWidth } from "../../utils/layout";

const isVendorized = process.env.REACT_APP_ERROR_BUTTON_TEXT;

const ErrorPageButton = ({ intl }) => {
  return (
    <Button
      variant={isVendorized && "vendorized"}
      as={process.env.REACT_APP_ERROR_BUTTON_URL ? "a" : Link}
      to="/"
      href={process.env.REACT_APP_ERROR_BUTTON_URL}
      text={
        process.env.REACT_APP_ERROR_BUTTON_TEXT ||
        intl.formatMessage({ id: "log_in" })
      }
      width={`calc(${calcColumnWidth(2, 6, "end", true)} - 16px)`}
    />
  );
};

export default injectIntl(ErrorPageButton);
