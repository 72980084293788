import React from "react";
import styled from "styled-components";

import logoLight from "../images/logo-light.svg";
import logoDark from "../images/logo-dark.svg";

const Header = styled.header`
  display: flex;
  height: 164px;
  justify-content: flex-end;
  padding: 56px 56px 0 0;
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    height: 124px;
  }

  @media only screen and (max-width: 940px) {
    justify-content: flex-start;
    padding: 24px 56px 0 0;
  }
`;

const Img = styled.img`
  z-index: 2;
`;

const Link = styled.a`
  text-decoration: none;
`;

const NavBar = ({ className, color }) => {
  return (
    <Header className={className}>
      <Link
        href={process.env.REACT_APP_CUSTOM_LOGO_URL || "https://platform.sh"}
      >
        <Img
          alt={process.env.REACT_APP_CLIENT_NAME || "Platform.sh"}
          src={color === "black" ? logoDark : logoLight}
        />
      </Link>
    </Header>
  );
};

export default NavBar;
