// Return the css string that will calculate the size of columnNumber col width
// Position is he position you want to width to go [start or end] of the last colunm
// Exact will remove the first [margin-between-columns] value to match exact column size
export const calcColumnWidth = (
  columnNumber,
  mobileColumnNumber = columnNumber,
  position = "end",
  exact = false
) => {
  if (window.matchMedia("(max-width: 940px)").matches) {
    if (!mobileColumnNumber) {
      return 0;
    }
    return `calc((((100vw - (24px*2) - (16px*5)) / 6) * ${
      position === "start" ? mobileColumnNumber - 1 : mobileColumnNumber
    }) + (${mobileColumnNumber - 1} * 16px))`;
  }

  return `calc((${
    position === "start" ? columnNumber - 1 : columnNumber
  } * var(--column-size)) + (${
    exact ? columnNumber - 1 : columnNumber
  } * var(--margin-between-columns)))`;
};
